.custom-control-label {
    color: #030765; /* Dark blue text color */
}

.card-header {
    background-color: #030765; /* Dark blue for card headers */
    color: white;
    font-weight: bold;
}

.add-remove-button {
    color: #030765; /* Yellow icon color for add/remove buttons */
}

/* Scrollable table container */
.edit-supplier-modal-body {
    height: calc(100vh - 320px);
    overflow-y: auto;
}

/* Customize scrollbar for WebKit browsers (Chrome, Safari, Edge) */
.edit-supplier-modal-body::-webkit-scrollbar {
    width: 12px;
}

.edit-supplier-modal-body::-webkit-scrollbar-track {
    background: #ffffff; /* White background for the track */
}

.edit-supplier-modal-body::-webkit-scrollbar-thumb {
    background-color: #0a0a64; /* Dark blue color for the thumb */
    border-radius: 9px;
    border: 3px solid #ffffff; /* Adds padding around the thumb */
    height: 5px;
}

.edit-supplier-modal-body::-webkit-scrollbar-thumb:hover {
    background-color: #ffc107; /* Yellow color on hover */
}