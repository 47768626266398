.App {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.TopBanner {
  background-color: #030765;
  text-align: center;
  height:88px;
}

.Pages {
  overflow-y: auto;
  height:57px;
}

.PageContainer {
  height: calc(100% - 145px);
}

.LeftPillar {
  height: 69vh;
  overflow-y: auto;
}

/* Customize scrollbar for WebKit browsers (Chrome, Safari, Edge) */
.LeftPillar::-webkit-scrollbar {
  width: 12px;
  
}

.LeftPillar::-webkit-scrollbar-track {
  background: #ffffff; /* White background for the track */
}

.LeftPillar::-webkit-scrollbar-thumb {
  background-color: #0a0a64; /* Dark blue color for the thumb */
  border-radius: 9px;
  border: 3px solid #ffffff; /* Adds padding around the thumb */
  height: 5px;
}

.LeftPillar::-webkit-scrollbar-thumb:hover {
  background-color: #ffc107; /* Yellow color on hover */
}

.VerticalTreeBreakdown {
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  width: 32%;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.Page {
  background-color: #ffffff;
  overflow-y: auto;
  display: flex; /* Ensure items stack vertically */
  height: 100%; /* Full height of the parent container */
  box-sizing: border-box;
}

.RightPillar {
  width: 75%;
  background-color: #ffffff;
  height: 100%;
  overflow-y: auto;
}

.ql-toolbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0; /* Stick to the top of the container */
  z-index: 1000; /* Make sure it stays above the content */
}

.ql-container {
  overflow-y: auto; /* Scroll vertically if content exceeds container height */
  height: calc(100vh - 620px);
  border-bottom: 1px solid #ddd; /* Optional: Add a bottom border to distinguish the container */
}

/* Customize scrollbar for WebKit browsers (Chrome, Safari, Edge) */
.ql-editor::-webkit-scrollbar {
  width: 12px;
  
}

.ql-editor::-webkit-scrollbar-track {
  background: #ffffff; /* White background for the track */
}

.ql-editor::-webkit-scrollbar-thumb {
  background-color: #0a0a64; /* Dark blue color for the thumb */
  border-radius: 9px;
  border: 3px solid #ffffff; /* Adds padding around the thumb */
  height: 5px;
}

.ql-editor::-webkit-scrollbar-thumb:hover {
  background-color: #ffc107; /* Yellow color on hover */
}

.form-control {
  color: #030765 !important; 
}

/* Apply default color and hover effect to all imported icons */
.fa-icon {
  color: #020654; /* Default icon color */
  transition: color 0.3s ease; /* Smooth transition for color */
}

/* Hover effect */
.fa-icon:hover {
  color: #ffc107 !important; /* Hover color */
}

/* Disabled state - prevents hover color */
.fa-icon[disabled] {
  color: #ccc !important; /* Disabled color */
  cursor: not-allowed;
}

.card-body {
  color: #020654 !important;
}
.custom-btn-primary {
  background-color: #030765 !important; /* Dark blue */
  border-color: #030765 !important;
  color: #ffffff !important; /* White text for contrast */
}

.custom-btn-primary:hover {
  background-color: #020654 !important; /* Darker shade on hover */
  border-color: #020654 !important;
}

.form-control:focus {
  border-color: var(--bs-warning) !important; /* Use your desired border color */
  box-shadow: 0 0 5px 2px var(--bs-warning) !important; /* Custom glow color */
  outline: none; /* Remove the default focus outline */
}

.form-select:focus {
  border-color: var(--bs-warning) !important; /* Use your desired border color */
  box-shadow: 0 0 5px 2px var(--bs-warning) !important; /* Custom glow color */
  outline: none; /* Remove the default focus outline */
}
/* Override Bootstrap CSS variables for table-striped */
.table.table-striped tbody tr:nth-of-type(odd) {
  --bs-table-striped-bg: #020654; /* Set to bg-warning color */
  --bs-table-striped-color: #ffffff;
}

.table.table-striped tbody tr:nth-of-type(even) {
  --bs-table-bg: #ffffff; /* Set even rows to white or any other color */
}

.table.table-striped tbody tr {
  --bs-table-hover-bg: #ffc107; /* Set even rows to white or any other color */
  
}

.btn-close:hover {
  --bs-btn-close-color: #ffc107;
}