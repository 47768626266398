/* Fixed header controls */
.header-controls {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white; /* Ensure background color matches */
    padding: 10px 0;
}

/* Fixed position for the index display */
.index-display {
    margin-left: auto;
    display: flex;
    align-items: center;
}

/* Scrollable table container */
.table-scroll-container {
    height: calc(100vh - 320px);
    overflow-y: auto;
    
}

.dropdown-supplier-table .dropdown-menu .dropdown-item:active,
.dropdown-supplier-table .dropdown-menu .dropdown-item.active {
    background-color: #ffc107 !important; /* Dark blue for the selected background */
    color: #0a0a64 !important; /* White text for contrast */
}

.dropdown-supplier-table .dropdown-menu .dropdown-item:hover {
    background-color: #ffc107 !important; /* Yellow for hover background */
    color: #0a0a64 !important; /* Dark blue text for contrast */
}


.dropdown-supplier-table .dropdown-menu .dropdown-item {
    color: #0a0a64; /* Direct color application */
}

/* Change the tick color to yellow */
.dropdown-supplier-table .dropdown-menu .form-check-input:checked {
    background-color: #0a0a64 !important; /* Yellow background when checked */
    border-color: #0a0a64 !important; /* Yellow border when checked */
}

/* Adjust checkmark icon color inside the checkbox */
.dropdown-supplier-table .dropdown-menu .form-check-input:checked::before {
    color: #ffffff !important; /* White checkmark color for contrast */
}

.table-scroll-container>table {
    --bs-table-color: #0a0a64;
}

/* Customize scrollbar for WebKit browsers (Chrome, Safari, Edge) */
.table-scroll-container::-webkit-scrollbar {
    width: 12px;
    
}

.table-scroll-container::-webkit-scrollbar-track {
    background: #ffffff; /* White background for the track */
}

.table-scroll-container::-webkit-scrollbar-thumb {
    background-color: #0a0a64; /* Dark blue color for the thumb */
    border-radius: 9px;
    border: 3px solid #ffffff; /* Adds padding around the thumb */
    height: 5px;
}

.table-scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: #ffc107; /* Yellow color on hover */
}

/* Make table header sticky */
.table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 5;
}
